<template>
    <div class="btn-section">
        <span class="call-scheduler-btn">Have Questions Before Making Your Decision? <button v-on:click="toggleScheduler">Schedule a Call &#x260E;</button></span>
        
        <SchedulerModal 
            v-bind:showScheduler="showScheduler"
            v-bind:toggleScheduler="toggleScheduler"
        />
    </div>
</template>

<script>
import SchedulerModal from "./SchedulerModalComponent.vue";

    export default {
        name: 'Button',
        components: {
            SchedulerModal,
        },
        methods: {
            toggleScheduler: function () {
            this.showScheduler = this.showScheduler ? false : true;
            },
        },
          data() {
            return {
                showScheduler: false,
            };
        },
    }
</script>

<style scope>

    .btn-section {
        background-color: #cb4e4e;
        padding-top: 5px;
        padding-bottom: 20px;
        font-size: 20px;
        text-align: center;
        box-shadow: 0 4px 2px -2px #b7b7b7;
        position: sticky;
        z-index: 1;
        top: 101px;
        color: #fff;
    }

    .call-scheduler-btn {
        font-weight: bold;
        margin-right: -4%;
    }

     .call-scheduler-btn button {
        border-radius: 5px;
        border: none;
        font-family: inherit;
        font-size: 18px;

        cursor: pointer;
        padding: 10px 40px;
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;
        outline: none;
        position: relative;

        background: #f9f9f9;
        color: #000;
        box-shadow: 0 6px #bebdbd;

        z-index: 19;
        margin-top: 10px;
        margin-bottom: 0px;
        margin-left: 2px;
    }

    .call-scheduler-btn button:after {
        content: '';
        position: absolute;
        z-index: -1;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
    }

    .call-scheduler-btn button:hover {
        box-shadow: 0 4px #bebdbd;
        top: 2px;
    }

    .call-scheduler-btn button:active {
        box-shadow: 0 0 #bebdbd;
        top: 6px;
    }

</style>
