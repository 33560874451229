<template>
  <div id="app">
    <!-- <IsUserIdle v-show="!hasAppeared" /> -->
    <Button v-if="showButton" />
    <SchedulerModal
      v-bind:showScheduler="showScheduler"
      v-bind:toggleScheduler="toggleScheduler"
    />
  </div>
</template>

<script>
import SchedulerModal from "./components/SchedulerModalComponent.vue";
import Button from "./components/Button.vue";
// import IsUserIdle from "./components/IsUserIdle";

export default {
  name: "App",
  components: {
    SchedulerModal,
    Button
    // IsUserIdle
  },
  methods: {
    toggleScheduler: function() {
      this.showScheduler = this.showScheduler ? false : true;
    }
  },
  data() {
    return {
      showScheduler: false,
      showButton: false,
      hasAppeared: false
    };
  },
  created() {
    const mouseEvent = () => {
      document.removeEventListener("mouseleave", mouseEvent);
      if (!this.showScheduler) {
        this.showScheduler = true;
        this.hasAppeared = true;
      }
    };

    setTimeout(() => {
      document.addEventListener("mouseleave", mouseEvent);
    }, 30000);

    setTimeout(() => {
      this.showButton = true;
    }, 45000);
  }
};
</script>
