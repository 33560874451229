import moment from "moment-timezone";
import momentBusiness from "moment-business-time";

export function validateForm(data) {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const currentDate = new Date();
  const selectedDate = data.bestTimeToCall;
  const messages = [];

  if (!momentBusiness(selectedDate).isWorkingTime()) {
    messages.push("Please select a timeslot within business hours.");
  }

  if (selectedDate < currentDate) {
    messages.push("Please select a future timeslot.");
  }

  if (data.phoneNumber.length < 10) {
    messages.push("Please enter a valid phone number.");
  }

  if (!data.email.match(validRegex)) {
    messages.push("Please enter a valid email address.");
  }

  return messages;
}

export function formatPhoneNumber(phoneNumber) {
  return phoneNumber
    .replace(/\D/g, "")
    .replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/g, (txt, f, s, t) => {
      if (t) {
        return `(${f}) ${s}-${t}`;
      } else if (s) {
        return `(${f}) ${s}`;
      } else if (f) {
        return `(${f})`;
      }
    });
}

export function getPrivacyPolicyLink(domain) {
  if (domain.includes("manward")) {
    return "https://manward.com/privacy-policy/";
  } else if (domain.includes("mta")) {
    return "https://monumenttradersalliance.com/privacy-policy/";
  } else {
    return "https://oxfordclub.com/privacy-policy/";
  }
}

export function submitForm(data) {
  const currentPageURL = window.location.href;
  //   const url =
  //     `http://127.0.0.1:8000/store?` +
  //     `&email=${data.email}` +
  //     `&firstName=${data.firstName}` +
  //     `&lastName=${data.lastName}` +
  //     `&phone=${data.phoneNumber}` +
  //     `&bestTimeToCall=${moment(data.bestTimeToCall)
  //       .utcOffset(0, true)
  //       .toISOString()}` +
  //     `&referringURL=${currentPageURL}` +
  //     `&promoCode=${getPromoCode(currentPageURL)}` +
  //     `&campaignName=${getCampaignName()}`;
  const url =
    `https://guqin.ocweb.tools/store?` +
    `&email=${data.email}` +
    `&firstName=${data.firstName}` +
    `&lastName=${data.lastName}` +
    `&phone=${data.phoneNumber}` +
    `&bestTimeToCall=${moment(data.bestTimeToCall)
      .utcOffset(0, true)
      .toISOString()}` +
    `&referringURL=${currentPageURL}` +
    `&promoCode=${getPromoCode(currentPageURL)}` +
    `&campaignName=${getCampaignName()}`;
  fetch(url, {
    method: "GET",
  }).then((res) => console.log(res));
  showConfirmationMessage(data.bestTimeToCall);
}

function getCampaignName() {
  if (
    window.Portrait &&
    window.Portrait.dataLayer &&
    window.Portrait.dataLayer.effort
  ) {
    return encodeURIComponent(window.Portrait.dataLayer.effort.CampaignName);
  } else {
    return "Not%20available";
  }
}

function getPromoCode(currentPageURL) {
  if (currentPageURL.includes("promoCode")) {
    return currentPageURL
      .slice(currentPageURL.search(/\bpromoCode\b/g))
      .slice(10, 18);
  } else if (currentPageURL.includes("pageNumber")) {
    let pageURLArray = currentPageURL.split("/");
    return pageURLArray[4];
  } else {
    return "Not%20available";
  }
}

export function showConfirmationMessage(datetime) {
  const bestTimeToCall = new Date(datetime);
  const schedulerMain = document.getElementById("scheduler-main");
  const confirmation = document.querySelector(".scheduler-confirmation");
  const circleLoader = document.querySelector(".circle-loader");
  const checkmark = document.querySelector(".checkmark");
  const confirmationMessage = document.querySelector(".confirmation-message");
  const time = bestTimeToCall.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });

  schedulerMain.style.display = "none";
  confirmation.style.display = "block";
  circleLoader.classList.toggle("load-complete");
  checkmark.style.display = "block";
  confirmationMessage.innerHTML =
    `Thank you for scheduling a time with one of our VIP specialists. Get your questions ready! We are excited to help you in any way that we can. Your call is reserved for ` +
    `${getMonthName(bestTimeToCall.getMonth())} ` +
    `${bestTimeToCall.getDate()} at ${time} E.S.T.`;
}

export function getMonthName(month) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[month];
}
